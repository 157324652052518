<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-button
          variant="primary"
          @click="print(check.no )"
        >
          print
        </b-button>
      </b-card>
    </b-col>
    <b-col class="text-center">
      <br>
      <br>
      <br>
      <br>
      <br>
      <b-card>

        <div id="header">
          <img
            src="@/assets/images/logo/logo.jpg"
            alt=""
          >
          <label
            id="text"
            for=""
          >
            رخصة سير مؤقتة  &nbsp; <br> <br> رقم :{{ check.no }}</label>
        </div>
        <div id="body">
          <div class="right">
            <table
              class="betweenRows"
            >
              <tr>
                <td id="firstTd">
                  رقم لوحة التجربة :
                </td>
                <td id="secondTd">
                  {{ check.plate_data.number }}
                </td>
              </tr>
              <tr style="border-spacing:0!important;">
                <td id="firstTd">
                  المالك :
                </td>
                <td id="secondTd">
                  {{ check.owner_data.name }}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div
                    class="separator"
                  >
                    أوصاف المركبة :

                  </div>
                </td>

              </tr>
              <tr>
                <td id="firstTd">
                  رقم الشاصية
                </td>
                <td id="secondTd">
                  {{ check.checkin_data.chassis_number }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  ماركة المركبة :
                </td>
                <td id="secondTd">
                  {{ check.car_data.manufacturer }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  وزن المـركـبة :
                </td>
                <td id="secondTd">
                  {{ check.car_data.wight }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  اللـــــــــــــون :
                </td>
                <td id="secondTd">
                  {{ check.color }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  تاريـخ الإصدار :
                </td>
                <td id="secondTd">
                  {{ check.created_at.substring(0, 10) }}
                </td>
              </tr>

              <tr>
                <td id="firstTd">
                  نظامية / تجميع :
                </td>
                <td id="secondTd">
                  {{ $t(check.checkin_data.type) }}
                </td>
              </tr>
            </table>
          </div>
          <div class="vertical" />
          <div class="left">
            <table class="betweenRows">
              <tr>
                <td id="firstTd">
                  الرقم التسلسلي :
                </td>
                <td id="secondTd">
                  :{{ check.no }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  هوية المالك :
                </td>
                <td id="secondTd">
                  {{ $t(check.owner_data.identification_type) }} / {{ check.owner_data.identify }}
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div>
                                    &nbsp;

                  </div>
                </td>

              </tr>
              <tr>
                <td id="firstTd">
                  سمة الرقم :
                </td>
                <td id="secondTd">
                  {{ check.plate_data.number }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  طــراز المركبة :
                </td>
                <td id="secondTd">
                  {{ check.car_data.model }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  رقم العداد :
                </td>
                <td id="secondTd">
                  {{ check.km }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  سنـــة الصنـــع :
                </td>
                <td id="secondTd">
                  {{ check.checkin_data.created_year }}
                </td>
              </tr>
              <tr>
                <td id="firstTd">
                  تاريــخ الانتهاء :
                </td>
                <td id="secondTd">
                  {{ check.expiration_date }}
                </td>
              </tr>

              <tr>
                <td id="firstTd">
                  عام الاستيراد :
                </td>
                <td id="secondTd">
                  {{ check.created_at.substring(0, 4) }}
                </td>
              </tr>
              <tr>
                <td
                  id="secondTd2"
                  colspan="2"
                  style="text-align: end"
                >

                  رئيس القسم <label for="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label><br> المهندس أحمد الصالح

                  <label for="">&nbsp;&nbsp;&nbsp;</label>
                </td>

              </tr>
            </table>
          </div>
        </div>
      </b-card>
      <b-card
        id="printMe"
        style="visibility: hidden"
      >
        <div
          style="background-color: white;
            width: 600px;
            height: 75px;"
        >
          <img
            style="margin-right: 100px!important;
                margin-left: 410px!important;
            margin-bottom: 250px;
position: fixed"
            src="@/assets/images/logo/logo.jpg"
            alt=""
          >

          <label
            style="
            font-family: Arial;
            color: red;
            margin-right: 500px;
            margin-left: 10px;
            margin-top: 10px;
            font-size: 14px;
            font-weight: bold;"
          >

            رخصة سير مؤقتة  &nbsp;  <br><br> رقم :{{ check.no }}</label>

        </div>
        <div
          style="background-color: white;
            width: 600px;
            height: 460px;"
        >
          <div
            style="
            position:absolute;
 position:absolute;
            left: 330px;"
          >
            <table
              style=" border-collapse:separate;
            border-spacing:0 10px;"
            >
              <tr>

                <td
                  style="
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  {{ check.plate_data.number }}
                </td>
                <td
                  style="  width: 100px;
            background-color: #00a1b5;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;
                  color: black;"
                >
                  : رقم لوحة التجربة
                </td>
              </tr>
              <tr style="border-spacing:0!important;">
                <td
                  style="
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  {{ check.owner_data.name }}
                </td>
                <td
                  style="  width: 100px;
            background-color: #00a1b5;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;
                  color: black;"
                >
                  : المالك
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div
                    style="
            color: black;
            font-size: 14px;
            font-weight: bold;
            font-family:'Traditional Arabic';
                    align-items: center;
                    text-align: right;
                    content: '';
                    flex: 1;      content: '';
            flex: 1;
            border-bottom: 1px solid #000;"
                  >
                    : أوصاف المركبة

                  </div>
                </td>

              </tr>
              <tr>

                <td
                  style="
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  {{ check.checkin_data.chassis_number }}
                </td>
                <td
                  style="  width: 100px;
            background-color: #00a1b5;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;
                  color: black;"
                >
                  : رقم الشاصية
                </td>
              </tr>
              <tr>

                <td
                  style="
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  {{ check.car_data.manufacturer }}
                </td>
                <td
                  style="  width: 100px;
            background-color: #00a1b5;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;
                  color: black;"
                >
                  : ماركة المركبة
                </td>
              </tr>
              <tr>
                <td
                  style="
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  {{ check.car_data.wight }}

                </td>
                <td
                  style="  width: 100px;
            background-color: #00a1b5;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;
                  color: black;"
                >
                  : وزن المركبة
                </td>
              </tr>
              <tr>

                <td
                  style="
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  {{ check.color }}
                </td>
                <td
                  style="  width: 100px;
            background-color: #00a1b5;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;
                  color: black;"
                >
                  : اللـــــــــــــون
                </td>
              </tr>
              <tr>

                <td
                  style="
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  {{ check.created_at.substring(0, 10) }}
                </td>
                <td
                  style="  width: 100px;
            background-color: #00a1b5;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;
                  color: black;"
                >
                  : تاريـخ الإصدار
                </td>
              </tr>

              <tr>

                <td
                  style="
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  {{ $t(check.checkin_data.type) }}
                </td>
                <td
                  style="  width: 100px;
            background-color: #00a1b5;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;
                  color: black;"
                >
                  : نظامية / تجميع
                </td>
              </tr>
            </table>
          </div>
          <div
            style="border-left: 4px dashed black;
            height: 400px;
            position:absolute;
            left: 320px;"
          />
          <div
            style=" position:absolute;
            left: 20px;"
          >
            <table
              style=" border-collapse:separate;
            border-spacing:0 10px;"
            >
              <tr>
                <td
                  style="
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  :{{ check.no }}
                </td>

                <td
                  style="  width: 100px;
            background-color: #00a1b5;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;
                  color: black;"
                >
                  :  الرقم التسلسلي
                </td>
              </tr>
              <tr>

                <td
                  style="
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  {{ $t(check.owner_data.identification_type) }} / {{ check.owner_data.identify }}
                </td>
                <td
                  style="  width: 100px;
            background-color: #00a1b5;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;
                  color: black;"
                >
                  : هوية المالك
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div>
                                    &nbsp;

                  </div>
                </td>

              </tr>
              <tr>

                <td
                  style="
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  {{ check.plate_data.number }}
                </td>
                <td
                  style="  width: 100px;
            background-color: #00a1b5;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;
                  color: black;"
                >
                  : سمة الرقم
                </td>
              </tr>
              <tr>

                <td
                  style="
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  {{ check.car_data.model }}
                </td>
                <td
                  style="  width: 100px;
            background-color: #00a1b5;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;
                  color: black;"
                >
                  : طــراز المركبة
                </td>
              </tr>
              <tr>

                <td
                  style="
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  {{ check.km }}
                </td>
                <td
                  style="  width: 100px;
            background-color: #00a1b5;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;
                  color: black;"
                >
                  : رقم العداد
                </td>
              </tr>
              <tr>

                <td
                  style="
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  {{ check.checkin_data.created_year }}
                </td>
                <td
                  style="  width: 100px;
            background-color: #00a1b5;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;
                  color: black;"
                >
                  : سنـــة الصنـــع
                </td>
              </tr>
              <tr>

                <td
                  style="
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  {{ check.expiration_date }}
                </td>
                <td
                  style="  width: 100px;
            background-color: #00a1b5;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;
                  color: black;"
                >
                  : تاريــخ الانتهاء
                </td>
              </tr>

              <tr>

                <td
                  style="
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  {{ check.created_at.substring(0, 4) }}
                </td>
                <td
                  style="  width: 100px;
            background-color: #00a1b5;
            border: #000 solid 2px;
            height: 35px;
            text-align: center;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;
                  color: black;"
                >
                  : عام الاستيراد
                </td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  style="text-align: start;width: 190px;
                  margin-left: 20px;
                  width: 100px;
            height: 35px;
            font-family: 'Traditional Arabic';
                  font-weight: bold;
                  font-size: 14px;

                  color: black;"
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  رئيس القسم <label for="" /> <br>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; المهندس أحمد الصالح    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                  <label for="">&nbsp;&nbsp;&nbsp;</label>
                </td>

              </tr>
            </table>
          </div>
        </div>
      </b-card>

    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    check() {
      return this.$store.getters['check/GetElement']
    },
  },
  created() {
    this.$store.dispatch('check/GetElement', this.$route.params.check)
  },
  methods: {
    async print(itemId) {
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=no',
          'titlebar=yes',
          'scrollbars=yes',
        ],
        styles: [
          '../../../../assets/scss/certificate.css',
        ],
        timeout: 10, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: itemId, // override the window title
        title: itemId,
      }
      // Pass the element id here
      await this.$htmlToPaper('printMe', { options })
    },
  },
}</script>
<style>
        .separator {
            color: black;
            font-size: 14px;
            font-weight: bold;
            font-family:"Traditional Arabic";
            display: flex;
            align-items: center;
            text-align: center;
            content: '';
            flex: 1;

        }

        .separator::before,
        .separator::after {
            content: '';
            flex: 1;
            border-bottom: 1px solid #000;
        }

        .separator:not(:empty)::before {
            margin-right: 10px;
        }

        .separator:not(:empty)::after {
            margin-left: 10px;
        }
        table {
            border-collapse: collapse;
        }
        .betweenRows {
            border-collapse:separate;
            border-spacing:0 10px;
        }
        #specific{
            height: 5px!important;
            border-collapse:separate;
            border-spacing:0!important;
            background-color: #1f9d57;
            margin-top: 0px;
        }
        #firstTd{
            width: 100px;
            background-color: #00a1b5;
            alignment: right;
            border: #000 solid 2px;
            height: 35px;
            font-family: "Traditional Arabic";
            font-weight: bold;
            font-size: 14px;
            color: black;
        }
        #secondTd{
            background-color: #fff;
            width: 190px;
            border: #000 solid 2px;
            height: 35px;
            font-family: "Traditional Arabic";
            font-weight: bold;
            font-size: 14px;

            color: black;
        }
        #secondTd2{
            width: 190px;
            height: 35px;
            font-family: "Traditional Arabic";
            font-weight: bold;
            font-size: 14px;

            color: black;
        }
        #body{
            background-color: white;
            width: 600px;
            height: 450px;
        }
        .vertical {
            border-left: 4px dashed black;
            height: 400px;
            position:absolute;
            left: 320px;
        }
        .left {
            position:absolute;
            left: 330px;
        }
        .right {

            position:absolute;
        }
        #header{
            background-color: white;
            width: 600px;
            height: 71px;
            text-align: end;
        }
        #text{
            font-family: Arial;
            text-space: 10px;
            color: red;
            font-size: 14px;
            font-weight: bold;
            text-align: center;
            margin-top:10px ;
            margin-bottom:10px!important;
            padding-bottom:10px!important;
        }
        img{
            margin-right: 380px!important;
            margin-bottom: 50px;
        }
</style>
